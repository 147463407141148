import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="bp">
            <div className="wrapper bp">
              <header>
                <h1 className="logo">
                  <a href="/">Neutrogena</a>
                </h1>
                <nav>
                  <a href="/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/neutrogena/o-nas/" className="link">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>| </span>
                  <a href="/budte-krasna/" className="section">
                    Buďte krásná
                   </a>
                   
                  <span />
                   
                  <span />
                </nav>
              </header>
              <div className="top">
                <h2>Péče o rty</h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/budte-krasna/">Buďte krásná</a> <span>&gt;</span>
                  Péče o rty
                </div>
              </div>
            </div>
          </div>
          <div className="submenu bpmenu">
            <div className="wrapper">
              <a href="/budte-krasna/sucha-pokozka/" className="link">
                Suchá pokožka
              </a>
              <a href="/budte-krasna/pece-o-plet/" className="link">
                Péče o pleť
              </a>
              <a href="/budte-krasna/pece-o-rty/" className="current">
                Péče o rty
              </a>
              <a href="/budte-krasna/pece-o-ruce/" className="link">
                Péče o ruce
              </a>
              <a href="/budte-krasna/pece-o-nohy/" className="link">
                Péče o nohy
              </a>
              <a href="/budte-krasna/pece-o-tlo/" className="link">
                Péče o tělo
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <a name="aboutAnchor" />
              <section className="first">
                <div className="bg usta1">
                  <div className="text full">
                    <h2>Rty jsou nejčastěji opomíjenou částí těla</h2>
                  </div>
                  <div className="text left">
                    <p>
                      Je to většinou proto, že zanedbáváme pravidelnou péči.
                    </p>
                    <p>
                      Kůže na rtech je zvláště jemná a potřebuje zvlášní
                      ochranu. Rty, které jsou jemné a lesklé, na rozdíl od
                      popraskaných, vypadají krásně a jsou znakem zdravé
                      pokožky.
                    </p>
                    <p>
                      Ačkoliv se kůže na rtech skládá z epidermu a dermu, vrstva
                      rohoviny je na rtech mnohem tenčí než na jiných částech
                      těla. Navíc rty postrádají melanin, který by je chránil
                      před škodlivými vlivy UV záření, a mazové žlázy, které
                      zajišťují řádnou hydrataci.
                    </p>
                    <p>
                      Vystavení slunci, silný vítr či extrémní teploty mohou
                      způsobit popraskání rtů. Mezi jiné příčiny suchých rtů
                      patří vědomé či nevědomé olizování a alergie. např. na
                      zubní pastu, která může být pro pokožku dráždivá.
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </section>
              <section className="last">
                <div className="bg usta2">
                  <div className="text right">
                    <h2>Hydratace</h2>
                    <p>
                      Bez ohledu na příčinu potíží popraskané rty potřebují
                      hydrataci, regeneraci <br />a ochranu proti nepříznivým
                      vlivům vnějších faktorů.
                    </p>
                    <p>
                      NEUTROGENA<sup>®&nbsp;</sup>Norská receptura balzámy na
                      rty přináší péči pro suché a popraskané rty a zlepšují
                      jejich vláčnost.
                    </p>
                    <p>
                      <br />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </section>
              <div id="fb_72"></div>
           </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/neutrogena/o-nas/">
                  O ZNAČCE NEUTROGENA<sup>®</sup>
                </a>
                <span>|</span>
                <a href="/neutrogena/norska-receptura/">Norská receptura</a>
                <span>|</span>
                <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
                <span>|</span>
                <a href="/produkty/">
                  Zobrazit produkty NEUTROGENA<sup>®</sup>
                </a>
              </div>
            </div>
          </div>
          <Footer></Footer>
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
